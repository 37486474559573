.instructions-container{
    position: absolute;
    background-color: #3399FF;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    user-select: none;
}

.instructions-content{
    width: 90%;
    padding-bottom: 2%;
    background-color: rgba(248, 246, 247, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.instructions-tab{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inactive-tab{
    display: none;
}

.summary-div{
    font-size: calc((4vh + 4vw)/2);
}

.instructions-label{
    padding: 2vh;
    font-weight: bold;
    font-size: calc((8vh + 8vw)/2);
    color: white;
    -webkit-text-stroke: calc((.3vh + .3vw)/2) black;
}


.instructions-section{
    border-radius: 25px;
    padding: 2%;
    margin-bottom: 5%;
    height: fit-content;
    width: 85%;
    border-style: solid;
    background: #2EC4B6;

    display: flex;
    align-items: center;
    justify-content: center;

    animation-name: test-div-float;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.instructions-section span{
    background-color: rgba(248, 246, 247, 0.5);;
    margin: 0 4%;
    padding: 4%;
    border-radius: 20px;
    font-size: calc((4vh + 4vw)/2);
    display: flex;
    justify-items: center;
    align-content: center;
}

@keyframes test-div-float {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}


/* navbar */

.instructions-nav{
    margin:0;
    padding: 0;
    top: 20vh;
    height: 15vh;
    width: 100%;
    background-color: #FF3366;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 100;
}

.instructions-nav a{
    border-radius: 25px;
    color: black;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: calc((3vh + 3vw)/2);
}

.instructions-nav a:hover{
    background-color: #ddd;
    color: black;
    cursor: pointer;
}

.instructions-nav a.active {
    background-color: rgba(248, 246, 247, 0.5);
    color: black;
}

.sticky {
    position: fixed;
    top: 0;
    width: 90%;
}

.add-label-padding{
    padding-top: calc(15vh + 2vh);
}

/* media */
.gif{
    height: calc((30vh + 30vw)/2);
    width: auto;
    max-height: none;
}

.pic-container{
    position: relative;
}

.pic{
    border-radius: 25px;
    height: calc((40vh + 40vw)/2);
    width: auto;
    max-height: none;
    transition: .5s ease;
}

.pic-caption{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.pic-text{
    background-color: rgba(255, 51, 102, 1);
    margin-bottom: 5%;
    padding: 5%;
    border-radius: 25px;
    font-size: calc((2.75vh + 2.75vw)/2);
}

.pic-container:hover .pic{
    opacity: 0.6;
}
  
.pic-caption:hover{
    opacity: 1;
}

/* dev */
.dev-section{
    border-radius: 25px;
    padding: 2%;
    margin-bottom: 5%;
    height: fit-content;
    width: 85%;
    border-style: solid;
    background: #2EC4B6;

    display: flex;
    align-items: center;
    justify-content: center;

    animation-name: test-div-float;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.dev-section span{
    background-color: rgba(248, 246, 247, 0.5);;
    margin: 0 4%;
    padding: 4%;
    border-radius: 20px;
    font-size: calc((3vh + 3vw)/2);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.dev-links{
    width: 50%;
    height: 10%;
    padding-top: 5%;
    display: flex;
    justify-content: space-around;
}

.dev-links img{
    height: calc((7vh + 7vw)/2);
    width: auto;
    border-radius: 50%;
}

.dev-links img:hover{
    background-color: #FF3366;
    cursor: pointer;
}
