.mobile{
    background-color: #3399FF;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-content{
    background-color: transparent;
    width: 75%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border: 1em solid black;
}


.mobile-content-message{
    width: 100%;
    height: 100%;
    background-color: #2EC4B6;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
}

.mobile-content-message h1{
    padding: 5%;
    text-align: center;
    font-size: calc(6vh + 6vw / 2);
}
