
.App{
  text-align: center;
  display: flex;
  justify-content: space-around;
  background-color: #3399FF;
  height: 80vh;
  min-height: 80vh;
  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
}

.home {font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #F6F7F8;
  margin-top: 5vh;
  margin-bottom: 1vh;
  padding: 1.5em;
  font-size: 3.75vh;
  user-select: none;
}

.home input{
  border-style: hidden;
  padding: 100px;
  width: 200px;
  height: 4vh;
  padding: 0;
  margin: 0;
  font-size: 3.5vh;
  text-align: center;
  cursor: text;
  background: transparent;
  border: 0;
  border-bottom: 2px solid grey;
}
.home input ~ .focus-border{
  position: absolute; 
  bottom: 0; 
  left: 0; 
  width: 0; 
  height: 2px; 
  background-color: #3a43ef; 
  transition: 0.4s;
  margin-left: 0;
}

.home input:focus{
  outline: none;
  border-bottom: hidden;
}

.effect-1 ~ .focus-border{
  position: absolute; bottom: 0; left: 0; width: 0; 
  height: 2px; 
  background-color: #3a43ef; 
  transition: 0.4s;}

.home input:focus ~ .focus-border{
  width: 100%; 
  transition: 0.4s;
}

.home .inputContainer {
  float: left;
  position: "relative";
  display: "inline";
  margin:0;
  padding: 0;
}

button{
  width: 25%;
  height: 25%;
  color: #494949 !important;
  text-decoration: none;
  background: #ffffff;
  border: 2px solid #494949 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
  outline: none;
  cursor: pointer;
  
}

button:hover{
  color: #ffffff !important;
  background: #3a43ef;
  border-color: #3a43ef !important;
  transition: all 0.4s ease 0s;
}

.button-disabled{
  opacity: .5;
  color: black !important;
  text-decoration: none;
  background: #ffffff;
  border: 2px solid #494949 !important;
  display: inline-block;
  transition: all 0.4s ease 0s;
}

.button-disabled:hover{
  color: black !important;
  background: #ffffff;
  border-color: #494949 !important;
  transition: none;
  cursor: initial;
}

.home button{
  height: 3.5vh;
  cursor: pointer;
}

.navBar{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 20vh;
  background: #011627;
  color: white;
  padding-left:5vw;
  padding-right:5vw;
}

.navBar h1{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 6.5vh;
  margin-top: 2vh;
  position: relative;
}

.navBar h1:hover{
  color: #2EC4B6;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 6.5vh;
  margin-top: 2vh;
}

.pic-logo {
  text-decoration: none;
  position: relative;
  margin: 0;
  padding: 0;
}

.pic-logo:hover h2, .pic-logo:hover h1{
  color: #2EC4B6;
}

.pic-logo img {
  display: inline;
  max-height: 20vh;
}

.pic-logo div {
  position: absolute;
  top: 60%;
  left: 34%;
  transform: translate(-50%, -50%);
}

.pic-logo h2 {
  font-size: 4.5vh;
  margin: 0;
  padding: 0;
  color: black;
}
.pic-logo h1 {
  margin: 0;
  padding: 0;
  font-size: 7.5vh;
  color: black;
}
/* 
//non-floating logo
.nav-logo {
  text-decoration: none;
} */

.floating{
  text-decoration: none;
  height: 30vh;
  max-height: 6em;
  width: 60vh;
  min-width: 30vw;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 3vh;
  margin-left: 3vw;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 5vw;
}

.nav-link {
  text-decoration: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  margin-left: 5vw;
  font-size: 6vh;
}

.nav-link:hover {
  color: #2EC4B6;
}

img {
  max-height: 20vh;  
}

.menu-option {
  margin-bottom: 10px;
}

.menu-option button {
  margin-left: 10px;
}

.menu-option span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-option span span {
  margin-left: 10px;
}

.alert{
  padding: 10px;
  background-color: #f44336;
  color: white;
}

.lobby-button-link{
  width: 25%;
  height: 3.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.lobby-button-link:link{
  text-decoration: none;
}

.lobby-button-link button{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

/* 
mobile detected
*/
.mobile-detected{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #3399FF;
  min-height: 50vh;
  min-width: 50vw;
  padding-left: 5vw;
  padding-right: 5vw;
}

.mobile-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  height: 10%;
  background-color: white;
}

* {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

*::-webkit-scrollbar{
  width: 0px; /* For Chrome, Safari, and Opera */
}
