.player-scores-div{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.player-scores{
    width: 100%;
    height: 100%;
    background-color: #3399FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scores-label{
    font-size: calc((5vh + 5vw)/2);
    color: white;
    -webkit-text-stroke: calc((.2vh + .2vw)/2) black;
}

.player-scores-div span{
    font-weight: bold;
}

.player-scores-div ul {
    font-size: calc((3vh + 3vw)/2);
    background-color: rgb(5, 5, 5, .1);
    list-style-type: none;
    margin:0;
    padding:0;
    padding-left: 2%;
    padding-right: 2%;
    width: 85%;
    height: 80%;
    text-align: left;
    overflow-y: scroll;
    overflow-x: hidden;
}

.player-scores-div li {
    font-size: calc((2.5vh + 2.5vw)/2);
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.li-name{
    margin-left: 5px;
}

.li-score{
    width: 25px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.players-target-score-label{
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10%;
}