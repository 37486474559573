.game-vote-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-vote{
    background-color: #2ec4b6;
    width: 100%;
    height: 85%;
    border: 10px solid #011627;
}

.top, .bottom{
    width: 100%;
    height: 50%;
}

.top{
    background-color: #ff3366;
}

.bottom{
    background-color: #2ec4b6;
    display: flex;
    justify-content: center;
}

.answer-summary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.category-letter-container{
    background-color: rgb(255, 255, 255, 25%);
    padding: 1%;
    border-radius: 5px;
    margin-bottom: 2%;
}

.category-letter{
    font-weight: bold;
}

.category-letter span{
    font-weight: normal;
}

.answer-summary .player{
    color: blue;
    font-size: calc((4vh + 4vw)/2);
}

.answer-summary .answer{
    font-weight: bold;
    font-size: calc((7vh + 7vw)/2);
    color: white;
    -webkit-text-stroke: calc((.3vh + .3vw)/2) black;
    user-select: text;
}

.answer-summary .answer::selection{
    color: white;
    background-color: #3399FF;
}

/* for vote area */

.vote-area{
    background-color: #2ec4b6;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vote-area .msg {
    padding-bottom: 5%;
    font-size: calc((4vh + 4vw)/2);
    font-weight: bold;
    margin: 0 5%;
}

.player-votes{
    width: 30%;
    height: 100%;
    background-color: #F6F7F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc((2vh + 2vw) / 2);
}

.player-votes ul{
    background-color: rgb(5, 5, 5, .1);
    list-style-type: none;
    margin:0;
    padding:0;
    padding-left: 2%;
    padding-right: 2%;
    width: 85%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.player-votes li{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.score-summary{
    width: 30%;
    height: 100%;
    background-color: #3399FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc((2vh + 2vw) / 2);
}

.score-summary ul{
    background-color: rgb(5, 5, 5, .1);
    list-style-type: none;
    margin:0;
    padding:0;
    padding-left: 2%;
    padding-right: 2%;
    width: 85%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.score-summary li{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}

.target-score-label{
    margin: 10px;
}

.vote-buttons-div{
    background-color: cadetblue;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.no-button, .yes-button{
    width: 40%;
    height: 75%;
    font-size: calc((4vh + 4vw) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ready-up-label{
    font-size: calc((2.5vh + 2.5vw) / 2);
}

.no-button:hover{
    background-color: red;
    border-color: red !important;
}

.yes-button:hover{
    background-color: green;
    border-color: green !important;
}

.vote-loading-text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    font-size: calc((4vh + 4vw) / 2);
    font-weight: bold;
    padding-bottom: 5%;
}

@keyframes blink {50% { color: transparent }}
.loader-vote-dot { animation: 1s blink infinite }
.loader-vote-dot:nth-child(2) { animation-delay: 250ms }
.loader-vote-dot:nth-child(3) { animation-delay: 500ms }

.convince-msg{
    font-size: calc((3vh + 3vw) / 2);
    margin: 0 5%;
}


.no-answer-div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: calc((5vh + 5vw)/2);
    color: white;
    -webkit-text-stroke: calc((.2vh + .2vw)/2) black;
}

.no-answer-div .player-name{
    color: #3399FF;
}
