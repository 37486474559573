.chat-container {
    background-color: rgb(32, 32, 32);
    /* height: calc(100vh - 12vw); */
    /* width: calc(75vh - 30vh); */
    height: 90%;
    width: 90%;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.phone {
    height: 85%;
    width: 90%;
    background-color: black;
}

.head-set{
    position: absolute;
    width: 40%;
    border-bottom: 5px solid grey;
    top: 4%
}

.home-button{
    position: absolute;
    width: 20%;
    height: 5px;
    border-bottom: 5px solid grey;
    bottom: 4%
}

.status-bar{
    height: 3%;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc((1.5vh + 1.5vh) / 2);
}

.status-bar .time{
    font-weight: bold;
    padding-left: 2%;
}

.status-bar .name{
    font-weight: bold;
    padding-right: 2%;
}

.group-name{
    height: 10%;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    -webkit-user-select: none;
    font-size: calc((3vh + 3vh) / 2);
}

.group-name span{
    font-weight: bold;
    margin-right: 1%;
}

.messages{
    list-style-type: none;
    margin:0 1% 1% 1%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 77%;
    margin-bottom: 0;
    overflow-x: scroll;
}

.message{
    height: auto;
    padding: 5px;
    margin: 1px;
    max-width: 100%;
    -webkit-user-select: none;
    display: inline-block;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-size: calc((3vh + 3vh) / 2);
}

.message-text{
    -webkit-user-select: text;
    margin-left: 3px;
}

.messageSelf {
    align-self: flex-end;
    background-color: #3399FF;
    border-radius: 10px 10px 0 10px ;
    text-align: left;
}

.messageOther {
    align-self: flex-start;
    background-color: lightgrey;
    border-radius: 10px 10px 10px 0;
    text-align: left;
}

.text-area{
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 10%;
}

.text-area input{
    border-style: hidden;
    width: 80%;
    height: 100%;
    background: transparent;
    color: white;
    float: left;
    margin-left: 5%;
    box-shadow: none;
    margin-right: 5px;
    font-size: calc((2vh + 2vh) / 2);
  }
  
  .text-area input:focus{
    outline: none;
    border-bottom: hidden;
    caret-color: #3399FF;
  }

  .text-area .submit {
    display: flex;
    justify-content: center;
    width: 10%;
    height: 50%;
    border-radius: 50px;
    color: green !important;
    text-transform: uppercase;
    text-decoration: none;
    background: greenyellow;
    border: 2px solid green !important;
    display: inline-block;
    transition: all 0.4s ease 0s;
    font-size: 5%;
  }
  
  .text-area .submit:hover {
    color: #ffffff !important;
    background: green;
    border-color: green !important;
    transition: all 0.4s ease 0s;
  }

  .system-msg{
      background-color: #ff3366;
      border-radius: 10px;
  }