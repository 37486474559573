.settings-container{
    z-index: 100;
    background-color: rgb(255, 255, 255, 75%);
    width: calc((7.5vh + 7.5vw)/2);
    height: calc((7.5vh + 7.5vw)/2);
    position: fixed;
    left: 2vh;
    bottom: 2vh;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settings-container img{
    width: calc((5vh + 5vw)/2);
    height: calc((5vh + 5vw)/2);
}

.settings-container:hover{
    background-color: #2EC4B6;
    cursor: pointer;
}

/* modal */

.settings-item{
    background-color: rgb(255, 255, 255, 25%);
    border-radius: 5px;
    width: 30%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: calc((2vh + 2vw)/2);
    font-weight: bold;
}

.settings-item-label{
    text-decoration: underline;
    margin-top: 3%;
}

.number-input{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-input{
    background-color: #F6F7F8;
    width: calc((4vh + 4vw)/2);
    height: calc((4vh + 4vw)/2);
    border-radius: 50%;
    font-size: calc((3vh + 3vw)/2);
    text-align: center;
    padding: 0;
    box-shadow: 0px 2px 5px rgba(94, 94, 94, 0.68);
}

.update-input span{
    line-height: calc((3.5vh + 3.5vw)/2);
}

.update-input:hover{
    background-color: #3399FF;
    cursor: pointer;
    transition: .3s;
}

.input-max{
    background-color: red;
}

.input-max:hover{
    background-color: red;
    cursor: initial;
}

.input-value{
    font-size: calc((7vh + 7vw)/2);
    margin: 0 5%;
}

.modal-content-style{
    background-color: #3399FF;
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    border: 5px solid #011627;
    overflow: auto;
    border-radius: 6px;
    outline: none;
    padding: 2.5%;
    user-select: none;
    -moz-user-select: none;
}

.modal-content-container{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-div, .settings-div{
    width: 90%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-label, .settings-label{
    justify-self: self-start;
    font-size: calc((4vh + 4vw)/2);
    font-weight: bold;
}

.menu-buttons-div, .settings-content-div{
    margin-top: 10px;
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(255, 255, 255, 25%);
}

.menu-buttons-div button{
    height: 70%;
    width: 30%;
    border-radius: 25px;
    font-size: calc((3.5vh + 3.5vw)/2);
}

.close-button{
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 8px;
    font-size: calc((4vh + 4vw)/2);
    font-weight: bold;
}

.close-button:hover{
    color: #F6F7F8;
}