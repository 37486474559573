.game-area-div{
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
}

/* Category/Letter Style */
.categories-container{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.category, .letter{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.category{
    background-color: #FF3366;
    border-bottom: solid #011627;
}

.category-text{
    font-size: calc((6vh + 6vw)/ 2);
}

.letter{
    background-color: #2EC4B6;
    border-top: solid #011627;
}
.letter-text{
    font-size: 14vh;
}

/* Countdown Style */
.countdown-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #011627;
}

.countdown-pregame{
    display: block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 25vmin;
    overflow: hidden;
    transition-duration: 0.2s;
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.countdown-fill{
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #2EC4B6;
}
.countdown-digit{
  width: 100%;
  color: #2EC4B6;
  text-align: center;
  mix-blend-mode: difference;
  pointer-events: none;
}

.loading-category{
    background-color: #2EC4B6;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-category-loading-text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    font-size: calc((4vh + 4vw) / 2);
    font-weight: bold;
    position: absolute;
}

@keyframes blink {50% { color: transparent }}
.loader-category__dot { animation: 1s blink infinite }
.loader-category__dot:nth-child(2) { animation-delay: 250ms }
.loader-category__dot:nth-child(3) { animation-delay: 500ms }

/* Game clock styles */
.countdown-game{
    position: absolute;
    height: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: grey;
    opacity: .35;
    bottom: 0;
}
